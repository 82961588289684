
import Vue from 'vue';
import Routes from '@/router/Routes';
import Component from 'vue-class-component';

@Component<ProfileRedirect>({})
export default class ProfileRedirect extends Vue {
  created(): void {
    this.$router
      .push({
        name: Routes.APPLICANT_DETAILS,
        params: {
          id: `${this.$route.params.id}`
        }
      })
      .catch((err) => console.error(err));
  }
}
